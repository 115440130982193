<template>
  <div class="row m-0">
    <div :class="validatorInfo ? 'detail-anunciante-white pb-4' : ''">
      <div v-if="validatorInfo" class="gpr-back-facturacion-title">
        <div class="gpr-float-left p-2 pl-4 gpr-facturation-title">Usuarios</div>
      </div>
      <div v-if="!validatorInfo" class="create-data-title text-start">Agregar usuarios a "{{ sdata.stationData.name }}"</div>
      <div v-if="!validatorInfo" class="pl-2 pr-2">
        <div class="create-data-subtitle mt-4 text-start">¿Quieres agregar a un miembro del equipo?</div>
      </div>
      <div class="register-token-right-subtitle text-start mt-3">Agrega usuarios a esta cuenta: crea uno nuevo o vincúlalo con uno existente. <br /> Por defecto, estará vinculado a tu cuenta.</div>
      <!-- *Boton al modal -->
      <div class="container-inputs-vuestic text-start mb-0">
        <button class="border mt-2" @click="setShowAddUser(true, false)" style="width: 153px; font-size: 14px;">Agregar Usuario</button> &nbsp;
        <button v-if="listCurrentUsers.length > 0" class="border mt-2" @click="setShowMatchUser(true, false)" style="width: 153px; font-size: 14px;">Vincula Usuario</button>
      </div>
      <div class="row m-0 text-start mt-4 create-data-add-titles create-data-border-bottom p-0">
        <div class="col-3 text-nowrap">Usuarios</div>
        <div class="col-5 text-nowrap">Correo electrónico</div>
        <div class="col-3 text-nowrap">Nivel de Acceso</div>
        <div class="col-1"></div>
      </div>
      <div v-for="(item, index) in listUsers" :key="index" class="row m-0 text-start register-token-right-subtitle create-data-border-bottom p-0">
        <div class="col-3 text-nowrap overflow-x-auto no-scrollbar">{{ item.name }}</div>
        <div class="col-5 text-nowrap overflow-x-auto no-scrollbar-mail">{{ item.email }}</div>
        <div class="col-3">{{ item.rol === 1 ? 'Administrador' : item.rol === 2 ? 'Editor' : item.rol === 3 ? 'Lector' : '' }}</div>
        <div class="col-1 pt-2 create-data-pointer text-end"><img v-if="listUsers.length > 1" :src="ImgDelete" @click="deleteUsers(item.id, item.email, index)" alt="" /></div>
      </div>
    </div>
    <!-- !MODAL -->
     <div v-if="showCurrentAddUser" class="modal-confirmation-container2 pb-5" style="z-index: 2000; position: fixed;">
      <div class="position-relative">
        <div class="create-data-img-right create-data-pointer" @click="setShowMatchUser(false, false)">
          <img :src="ImgRight" alt="" />
        </div>
        <div class="create-data-img-right create-data-pointer pt-4 pr-4" @click="setShowMatchUser(false, false)">
          <img :src="ImgClose" alt="" />
        </div>
        <div class="create-data-img-left">
          <img :src="ImgLeft" alt="" />
        </div>
        <div class="create-data-title text-start">Vincular usuarios</div>
        <div class="row m-0 gap-1 text-start mt-4 create-data-add-titles create-data-border-bottom p-0">
          <div class="col-6">Usuarios</div>
          <div class="col-3">Nivel de Acceso</div>
          <div class="col-1"></div>
        </div>
        <div v-for="(item, index) in listCurrentUsers" :key="index" class="row gap-1 m-0 text-start register-token-right-subtitle create-data-border-bottom p-0">
          <div class="col-6 table-over">{{ item.name }}</div>
          <div class="col-3">{{ item.role }}</div>
          <div class="col-1">
            <button class="green-new ml-4" @click="setShowMatchUser(false, true, item, index)" style="width: 100px; font-size: 12px;">Vincular</button>
          </div>
        </div>
         <div class="row mt-4 justify-content-end container-inputs-vuestic text-end mt-3">
          <div class="p-0">
            <button class="textgreen" @click="setShowMatchUser(false, false)" style="width: 194px;">Descartar</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showAddUser" class="modal-confirmation-container2 pb-5" style="z-index: 1000000;">
      <div class="position-relative">
        <div class="create-data-img-right create-data-pointer" @click="setShowAddUser(false, false)">
          <img :src="ImgRight" alt="" />
        </div>
        <div class="create-data-img-right create-data-pointer pt-4 pr-4" @click="setShowAddUser(false, false)">
          <img :src="ImgClose" alt="" />
        </div>
        <div class="create-data-img-left">
          <img :src="ImgLeft" alt="" />
        </div>
        <div class="create-data-title text-start">Agregar usuario</div>
        <div class="register-token-right-subtitle text-start">Crea un nuevo usuario para vincularlo a esta cuenta.</div>
        <va-form ref="form">
          <div class="row m-0">
            <div class="col-6">
              <div class="container-inputs-vuestic mt-4">
                <label>Correo electrónico</label>
                <va-input
                  v-model="dataUser.email"
                  :rules="[(v) => v.match(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/) || 'Ingresa un correo valido']"
                  placeholder="correo@mail.com"
                />
                <span class="text-danger" v-if="flagEmail">{{ messageErrorEmail }}</span>
              </div>
            </div>
            <div class="col-6">
              <div class="container-inputs-vuestic mt-4">
                <label>Nombre</label>
                <va-input
                  v-model="dataUser.name"
                  :rules="[(v) => v.length > 8 || 'Ingresa un nombre']"
                  placeholder="Nombre y Apellidos"
                />
              </div>
            </div>
          </div>
          <div class="d-flex flex-row bd-highlight">
            <div class="container-inputs-vuestic2">
              <label class="text-start">Emisora</label>
              <div class="create-data-station-input4 create-data-station-input5 create-data-100 create-data-pointer position-relative mt-2">
                <div>
                  <div class="text-start" style="color: #000;">{{ nameCompany === '' ? sdata.stationData.name : nameCompany }}</div>
                </div>
                <div class="create-data-station-w" style="top: 10px;">
                  <img :src="ImgDown" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row bd-highlight mt-2">
            <div class="container-inputs-vuestic2">
              <label class="text-start">Nivel de Acceso</label>
              <div class="create-data-station-input4 create-data-100 create-data-pointer position-relative mt-2">
                <div @click="showModalRol(true)">
                  <div class="text-start" :style="dataUser.rol === 0 ? 'color: #A0A0A0;' : 'color: #000000;'">{{ dataUser.rol === 1 ? 'Administrador' : dataUser.rol === 2 ? 'Editor' : dataUser.rol === 3 ? 'Lector' : 'Selecciona una opción' }}</div>
                </div>
                <div class="create-data-station-w" style="top: 10px;" @click="showModalRol(true)">
                  <img :src="ImgDown" alt="" />
                </div>
                <div v-if="showRol" class="create-data-add-rol pl-3 pr-3">
                  <div v-for="(item, index) in rols" :key="index" @click="showModalRol(false, item.rol)" @mouseover="createHover(index)" @mouseout="createHover(-1)" :style="overTrue === index ? 'background: #F2F2F2;' : ''">
                    <div class="d-flex flex-row bd-highlight">
                      <div v-if="item.rol === 1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                          <g clip-path="url(#clip0_2252_2684)">
                            <path d="M9.0892 9.24235C9.0892 10.4203 8.05434 11.3789 6.76758 11.3789C5.48081 11.3789 4.44595 10.414 4.44595 9.24235H0.517578V9.73107C0.517578 10.1383 0.837567 10.4704 1.25968 10.5143L1.77711 10.5769C1.88604 10.909 2.02902 11.2474 2.21965 11.5544L1.91328 11.9178C1.62733 12.2311 1.64775 12.6822 1.95412 12.9642L2.73027 13.6659C3.03664 13.9479 3.52684 13.9792 3.85363 13.7286L4.26213 13.4215C4.60255 13.6033 4.95658 13.7348 5.32422 13.8413L5.37869 14.2862C5.42635 14.706 5.78719 14.9942 6.22972 14.9942H7.29862C7.74116 14.9942 8.102 14.6997 8.13604 14.3113L8.21093 13.8351C8.57858 13.7348 8.93942 13.5907 9.25941 13.4153L9.6679 13.7223C10.0083 13.9792 10.4985 13.9479 10.8049 13.6722L11.5674 12.9579C11.8738 12.6759 11.9078 12.2248 11.6355 11.9241L11.3019 11.5481C11.4993 11.2348 11.6423 10.909 11.7512 10.5707L12.2346 10.508C12.6772 10.4642 13.0108 10.1321 13.0108 9.72481V9.23608H9.08239L9.0892 9.24235Z" fill="url(#paint0_linear_2252_2684)"/>
                            <path d="M9.0892 9.24235C9.0892 10.4203 8.05434 11.3789 6.76758 11.3789C5.48081 11.3789 4.44595 10.414 4.44595 9.24235H0.517578V9.73107C0.517578 10.1383 0.837567 10.4704 1.25968 10.5143L1.77711 10.5769C1.88604 10.909 2.02902 11.2474 2.21965 11.5544L1.91328 11.9178C1.62733 12.2311 1.64775 12.6822 1.95412 12.9642L2.73027 13.6659C3.03664 13.9479 3.52684 13.9792 3.85363 13.7286L4.26213 13.4215C4.60255 13.6033 4.95658 13.7348 5.32422 13.8413L5.37869 14.2862C5.42635 14.706 5.78719 14.9942 6.22972 14.9942H7.29862C7.74116 14.9942 8.102 14.6997 8.13604 14.3113L8.21093 13.8351C8.57858 13.7348 8.93942 13.5907 9.25941 13.4153L9.6679 13.7223C10.0083 13.9792 10.4985 13.9479 10.8049 13.6722L11.5674 12.9579C11.8738 12.6759 11.9078 12.2248 11.6355 11.9241L11.3019 11.5481C11.4993 11.2348 11.6423 10.909 11.7512 10.5707L12.2346 10.508C12.6772 10.4642 13.0108 10.1321 13.0108 9.72481V9.23608H9.08239L9.0892 9.24235Z" fill="url(#paint1_linear_2252_2684)"/>
                            <path d="M6.76762 4.18595C8.02349 4.18595 9.04158 3.249 9.04158 2.09322C9.04158 0.937436 8.02349 0.000488281 6.76762 0.000488281C5.51174 0.000488281 4.49365 0.937436 4.49365 2.09322C4.49365 3.249 5.51174 4.18595 6.76762 4.18595Z" fill="url(#paint2_linear_2252_2684)"/>
                            <path d="M6.76762 4.18595C8.02349 4.18595 9.04158 3.249 9.04158 2.09322C9.04158 0.937436 8.02349 0.000488281 6.76762 0.000488281C5.51174 0.000488281 4.49365 0.937436 4.49365 2.09322C4.49365 3.249 5.51174 4.18595 6.76762 4.18595Z" fill="url(#paint3_linear_2252_2684)"/>
                            <path d="M11.0364 7.98921H2.49878C3.06387 6.31628 4.75913 5.10074 6.76076 5.10074C8.65346 5.10074 10.4168 6.19723 11.0364 7.98921Z" fill="url(#paint4_linear_2252_2684)"/>
                            <path d="M11.0364 7.98921H2.49878C3.06387 6.31628 4.75913 5.10074 6.76076 5.10074C8.65346 5.10074 10.4168 6.19723 11.0364 7.98921Z" fill="url(#paint5_linear_2252_2684)"/>
                          </g>
                          <defs>
                            <linearGradient id="paint0_linear_2252_2684" x1="0.517578" y1="14.9942" x2="13.0108" y2="14.9942" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#1677FB"/>
                              <stop offset="0.65625" :stop-color="overTrue === index ? '#16D2FB' : '#000000'"/>
                            </linearGradient>
                            <linearGradient id="paint1_linear_2252_2684" x1="5.3458" y1="1.98699" x2="6.45095" y2="28.0611" gradientUnits="userSpaceOnUse">
                              <stop :stop-color="overTrue === index ? '#16D2FB' : '#000000'"/>
                              <stop offset="0.73306" :stop-color="overTrue === index ? '#16D2FB' : '#000000'" stop-opacity="0.28"/>
                            </linearGradient>
                            <linearGradient id="paint2_linear_2252_2684" x1="4.49365" y1="4.18595" x2="9.04158" y2="4.18595" gradientUnits="userSpaceOnUse">
                              <stop :stop-color="overTrue === index ? '#16D2FB' : '#000000'"/>
                              <stop offset="0.65625" :stop-color="overTrue === index ? '#16D2FB' : '#000000'"/>
                            </linearGradient>
                            <linearGradient id="paint3_linear_2252_2684" x1="6.25128" y1="-5.26871" x2="7.84673" y2="13.583" gradientUnits="userSpaceOnUse">
                              <stop :stop-color="overTrue === index ? '#16D2FB' : '#000000'"/>
                              <stop offset="0.73306" :stop-color="overTrue === index ? '#16D2FB' : '#000000'" stop-opacity="0.28"/>
                            </linearGradient>
                            <linearGradient id="paint4_linear_2252_2684" x1="2.49878" y1="7.98921" x2="11.0364" y2="7.98921" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#1677FB"/>
                              <stop offset="0.65625" :stop-color="overTrue === index ? '#16D2FB' : '#000000'"/>
                            </linearGradient>
                            <linearGradient id="paint5_linear_2252_2684" x1="5.79828" y1="1.46436" x2="6.20556" y2="14.5548" gradientUnits="userSpaceOnUse">
                              <stop :stop-color="overTrue === index ? '#16D2FB' : '#000000'"/>
                              <stop offset="0.73306" :stop-color="overTrue === index ? '#16D2FB' : '#000000'" stop-opacity="0.28"/>
                            </linearGradient>
                            <clipPath id="clip0_2252_2684">
                              <rect width="12.5" height="15" fill="white" transform="translate(0.517578 0.000488281)"/>
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div v-if="item.rol === 2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                          <g clip-path="url(#clip0_2252_1926)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9022 1.57343L12.6959 0.367322C12.2076 -0.120996 11.4144 -0.120996 10.926 0.367322L1.24281 10.0474C1.18956 10.1006 1.15284 10.1704 1.13632 10.2457L0.524922 13.2857C0.519414 13.3114 0.517578 13.3353 0.517578 13.361C0.517578 13.5739 0.690165 13.7483 0.90498 13.7483C0.930684 13.7483 0.956388 13.7465 0.982093 13.741L4.02439 13.1297C4.09967 13.115 4.1676 13.0783 4.22268 13.0232L13.904 3.34312C14.3924 2.85297 14.3924 2.06175 13.904 1.57343H13.9022ZM1.39887 12.8672L1.8083 10.8313L3.43502 12.4578L1.39887 12.8672ZM4.11068 12.0392L2.22692 10.1575L10.2779 2.10948L12.1598 3.99115L4.11252 12.0392H4.11068ZM13.3551 2.79606L12.707 3.44409L10.825 1.56242L11.4731 0.91622C11.6604 0.730806 11.9615 0.730806 12.1488 0.91622L13.3569 2.12233C13.4469 2.21228 13.4964 2.33344 13.4964 2.46011C13.4964 2.58678 13.4469 2.70794 13.3569 2.7979L13.3551 2.79606Z" :fill="overTrue === index ? '#16D2FB' : '#000000'"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_2252_1926">
                              <rect width="13.75" height="13.75" fill="white" transform="translate(0.517578 0.000488281)"/>
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div v-if="item.rol === 3">
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="10.7676" cy="10.5005" r="10" fill="url(#paint0_linear_2252_2695)"/>
                          <circle cx="10.7676" cy="10.5005" r="10" fill="url(#paint1_linear_2252_2695)"/>
                          <g clip-path="url(#clip0_2252_2695)">
                          <path d="M17.8661 6.70138C17.8124 6.70138 17.7587 6.71239 17.7097 6.7344L17.2608 6.92776L17.2671 6.06941C17.2671 5.89019 17.1486 5.73298 16.9748 5.68739C14.7321 5.08371 13.0584 6.08356 11.391 6.7061C10.0998 6.22347 8.80378 5.51446 7.24071 5.50031C6.7887 5.49717 6.31298 5.55062 5.80881 5.68582C5.63496 5.73298 5.51643 5.89019 5.51643 6.06783L5.52275 6.92619L5.0739 6.73125C4.87318 6.64479 4.64085 6.73597 4.55393 6.93562C4.5318 6.98436 4.52074 7.03781 4.52074 7.09283L4.51758 15.1073C4.51758 15.3242 4.69459 15.5003 4.91269 15.5003H17.8725C18.0906 15.5003 18.2676 15.3242 18.2676 15.1073L18.2613 7.09283C18.2613 6.87588 18.0842 6.69981 17.8661 6.69981V6.70138ZM5.30781 14.7143L5.31255 7.69337L5.53065 7.78769L5.57333 14.0603C5.57491 14.2772 5.7535 14.4517 5.9716 14.4502C5.99215 14.4502 6.01269 14.4486 6.03166 14.4454C7.80493 14.1578 8.92074 14.3496 9.8216 14.7143H5.30781ZM10.9975 14.4124C9.92433 13.8402 8.5683 13.334 6.36039 13.6044L6.30982 6.37596C6.60537 6.31622 6.88985 6.28793 7.16643 6.28635C8.51456 6.27692 9.66039 6.89003 10.9975 7.39781V14.4124ZM11.7877 7.39624C13.1232 6.88846 14.269 6.27535 15.6171 6.28478C15.8937 6.28635 16.1766 6.31465 16.4738 6.37439L16.4232 13.6028C14.2184 13.3324 12.8608 13.8371 11.7877 14.4109V7.39781V7.39624ZM12.962 14.7127C13.8628 14.348 14.9802 14.1562 16.7519 14.4439C16.9669 14.4785 17.1707 14.3338 17.2055 14.1185C17.2087 14.098 17.2102 14.0776 17.2102 14.0587L17.2545 7.78455L17.471 7.69022L17.4758 14.7111H12.9604L12.962 14.7127Z" fill="white"/>
                          </g>
                          <defs>
                          <linearGradient id="paint0_linear_2252_2695" x1="0.767578" y1="20.5005" x2="20.7676" y2="20.5005" gradientUnits="userSpaceOnUse">
                          <stop :stop-color="overTrue === index ? '#16D2FB' : '#000000'"/>
                          <stop offset="0.65625" :stop-color="overTrue === index ? '#16D2FB' : '#000000'"/>
                          </linearGradient>
                          <linearGradient id="paint1_linear_2252_2695" x1="8.49694" y1="-24.6781" x2="16.7703" y2="65.288" gradientUnits="userSpaceOnUse">
                          <stop :stop-color="overTrue === index ? '#16D2FB' : '#000000'"/>
                          <stop offset="0.73306" :stop-color="overTrue === index ? '#16D2FB' : '#000000'" stop-opacity="0.28"/>
                          </linearGradient>
                          <clipPath id="clip0_2252_2695">
                          <rect width="13.75" height="10" fill="white" transform="translate(4.51758 5.50049)"/>
                          </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div class="ml-2" :style="overTrue === index ? 'color: #16D2FB;' : 'color: #000000;'">{{ item.name }}</div>
                    </div>
                    <div class="text-start">{{ item.description }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </va-form>
        <div class="row m-0 justify-content-end container-inputs-vuestic text-end mt-3">
          <div class="p-0">
            <button class="textgreen" @click="setShowAddUser(false, false)" style="width: 194px;">Descartar</button>
            <button :class="btnEnabled ? 'green ml-4' : 'disabled ml-4'" @click="btnEnabled ? setShowAddUser(false, true) : ''" style="width: 194px;">Crear Usuario</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!validatorInfo" class="row m-0 justify-content-center container-inputs-vuestic register-call-to-action-bottom2 text-center mb-0">
      <div>
        <button class="textgreen" @click="addUsers(1)">Completar más tarde</button>
        <button :class="btnEnabled2 ? 'green ml-4' : 'disabled ml-4'" @click="btnEnabled2 ? addUsers(2) : ''">Siguiente</button>
      </div>
    </div>
  </div>
</template>
<script>
import ImgDelete from '@/assets/addEmisora/trash-03.png'
import ImgDown from '@/assets/addEmisora/chevron-down.png'
import ImgAdministrador from '@/assets/addEmisora/img-administrador.png'
import ImgEditor from '@/assets/addEmisora/img-editor.png'
import ImgLector from '@/assets/addEmisora/img-lector.png'
import ImgRight from '@/assets/addEmisora/Group4.png'
import ImgLeft from '@/assets/addEmisora/Group3.png'
import ImgClose from '@/assets/addEmisora/x-01.png'
import { saveUser, deleteUser, userListClient, updateUsers } from '@/services/registerStation'
import { useStore } from 'vuex'
import { ref } from 'vue'
import router2 from '@/router'
import { validateEmailExistToken, setUserClient, getUserNewClient } from '@/services/users'

export default {
  name: 'AddUsers',
  props: {
    setStateSteps: {
      type: Function
    },
    validatorInfo: {
      type: Boolean,
      default: false
    },
    clientId: {
      type: Number,
      default: 0
    },
    nameCompany: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const store = useStore()
    const sdata = store.state.station
    const listUsers = ref([])
    const listCurrentUsers = ref([])
    function addUsers (step) {
      store.commit('addUsers', this.listUsers)
      const objs = {
        radioStationId: sdata.stationData.idStation,
        step: step
      }
      updateUsers(objs).then((response) => {
        if (step === 2) {
          props.setStateSteps(4)
        } else {
          router2.push({ name: 'RadioListView' })
        }
      })
    }
    return {
      sdata,
      addUsers,
      listUsers,
      listCurrentUsers
    }
  },
  data () {
    return {
      flagEmail: false,
      messageErrorEmail: '',
      ImgDelete: ImgDelete,
      ImgDown: ImgDown,
      ImgAdministrador: ImgAdministrador,
      ImgEditor: ImgEditor,
      ImgLector: ImgLector,
      ImgRight: ImgRight,
      ImgLeft: ImgLeft,
      ImgClose: ImgClose,
      dataUser: {
        name: '',
        email: '',
        station: '',
        rol: 0
      },
      options: ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'],
      value: '',
      rols: [
        {
          name: 'Administrador',
          description: 'Es capaz de crear y eliminar nuevos anunciantes y usuarios, crear y administrar campañas.',
          rol: 1
        },
        {
          name: 'Editor',
          description: 'Es capaz de crear y administrar campañas.',
          rol: 2
        } /*,
        {
          name: 'Lector',
          description: 'Es capaz de ver los datos de anunciantes y sus campañas.',
          rol: 3
        } */
      ],
      showRol: false,
      showAddUser: false,
      showCurrentAddUser: false,
      overTrue: -1
    }
  },
  created () {
    this.dataUser.station = this.sdata.stationData.name
    this.getUserClient()
    if (this.sdata.listUsers.length > 0) {
      this.listUsers = []
      const vm = this
      this.sdata.listUsers.filter(function (objs) {
        vm.listUsers.push(objs)
        return true
      })
    }
  },
  methods: {
    userLists () {
      const typerd = localStorage.getItem('typerd')
      const vm = this
      let clientId = 0
      if (typerd === '1') {
        clientId = this.sdata.stationData.idStation
      } else {
        clientId = this.clientId
      }
      userListClient(clientId, typerd).then((response) => {
        const dataUsers = response.data
        vm.listUsers = []
        if (dataUsers.length > 0) {
          dataUsers.forEach(function (objs, index) {
            if (objs.users[0].status < 2) {
              vm.listUsers.push({ id: objs.users[0].userId, name: objs.users[0].name, email: objs.users[0].email, station: vm.dataUser.station, rol: objs.role.roleId })
            }
          })
        }
      })
    },
    deleteUsers (id, email, index) {
      const vm = this
      const objs = {
        id: id,
        email: email
      }
      deleteUser(objs).then((response) => {
        vm.listUsers.splice(index, 1)
      })
    },
    createHover (value) {
      this.overTrue = value
    },
    setShowAddUser (value, validator) {
      const typerd = localStorage.getItem('typerd')
      if (validator) {
        const objs = {
          fullname: this.dataUser.name,
          email: this.dataUser.email,
          phone: '',
          role: this.dataUser.rol,
          pass: '',
          id: this.sdata.stationData.idStation === 0 ? this.clientId : this.sdata.stationData.idStation,
          type: typerd,
          parentId: localStorage.getItem('idrd')
        }
        const vm = this
        validateEmailExistToken(this.dataUser.email).then(response => {
          const validate = response.data
          console.log('validate', validate)
          if (parseInt(validate.data) === 1) {
            this.messageErrorEmail = 'El correo ya existe, favor de elegir otro'
            this.flagEmail = true
          } else {
            this.flagEmail = false
            this.messageErrorEmail = ''
            saveUser(objs).then((response) => {
              vm.listUsers.push({ id: response.data, name: vm.dataUser.name, email: vm.dataUser.email, station: vm.dataUser.station, rol: vm.dataUser.rol })
              this.dataUser.name = ''
              this.dataUser.email = ''
              this.dataUser.rol = 0
              this.showAddUser = value
            })
          }
        })
      } else {
        this.dataUser.name = ''
        this.dataUser.email = ''
        this.dataUser.rol = 0
        this.showAddUser = value
      }
    },
    showModalRol (value, rol) {
      this.showRol = value
      if (!value) {
        this.dataUser.rol = rol
      }
    },
    setShowMatchUser (value, validator, element, index) {
      console.log('index3', index)
      this.showCurrentAddUser = value
      if (validator) {
        const obj = {
          userId: element.id,
          clientId: this.sdata.stationData.idStation,
          role: element.roleId,
          type: localStorage.getItem('typerd'),
          parentId: localStorage.getItem('idrd')
        }
        setUserClient(obj).then(response => {
          this.listCurrentUsers.splice(index, 1)
          this.listUsers.push({
            id: element.id,
            name: element.name,
            email: element.email,
            advertiser: this.dataUser.advertiser,
            rol: element.roleId
          })
        })
      }
    },
    getUserClient () {
      getUserNewClient(localStorage.getItem('idrd'), localStorage.getItem('typerd')).then(response => {
        console.log(response.data)
        for (const user of response.data) {
          this.listCurrentUsers.push({
            id: user.userId,
            name: user.name,
            email: user.email,
            role: user.role,
            roleId: user.roleId
          })
        }
      })
    }
  },
  mounted () {
    this.userLists()
  },
  components: {
  },
  computed: {
    btnEnabled2 () {
      if (this.listUsers.length > 0) {
        return true
      }
      return true
    },
    btnEnabled () {
      if (this.dataUser.email !== '' && this.dataUser.name !== '' && this.dataUser.rol !== 0 && (this.sdata.stationData.idStation !== 0 || this.clientId !== 0)) {
        return true
      }
      return false
    }
  }
}
</script>
<style scoped>
.no-scrollbar {
  box-shadow: inset -6px 0 8px -7px rgba(0, 0, 0, 0.2);
  height: 40px;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.no-scrollbar-mail {
  height: 40px;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.no-scrollbar-mail::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>
